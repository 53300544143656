import React, {useState} from 'react'
import Navbar2 from '../components/Navbar2.js'
import Footer from '../components/Footer'
import Team from '../components/Team.js'
import AboutInfo from '../components/AboutInfo.js'
import Header from '../components/Header'
import Footer2 from '../components/MiniCenteredFooter.js'
import AnimationRevealPage from './AnimationRevealPage.js'

function About() {
    return (
        <>
            <AnimationRevealPage>
            <Header /> 
            {/* <Navbar2 /> */}
            <AboutInfo />
            <Team />
            <Footer2 />
            </AnimationRevealPage>
            
         </>
 
    
    );
}

export default About