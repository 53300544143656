import React, {useState} from 'react'
import Navbar from '../components/Navbar.js'
import Homelayout from '../components/HomeLayout'
import HomeContent from '../components/HomeContent'
import Footer from '../components/Footer'
import HomeContent2 from '../components/HomeContent2.js'
import AnimationRevealPage from './AnimationRevealPage.js'
import Hero from '../components/BackgroundAsImage.js'
import Footer2 from '../components/MiniCenteredFooter.js'

function Landing() {
    return (
        <>
            <Hero />
            {/* <Navbar/> */}
            
            {/* <Homelayout /> */}
            <HomeContent />
            <AnimationRevealPage>
            <HomeContent2 />
            <Footer2 />
            </AnimationRevealPage>
            {/* <Footer /> */}
         </>
 
    
    );
}

export default Landing