import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "./Headings.js";
import { SectionDescription } from "./Typography.js";
import { Container, ContentWithPaddingXl } from "./Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
// import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
// const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-bold font-body`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-base sm:text-base leading-relaxed font-body`);

// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;



export default ({
  subheading = "FAQS",
  heading = "You Have Questions?",
  faqs = [
    {
      question: "How is Modern Golf different than traditional golf course staffing and management companies?",
      answer:
        "Unlike other golf management companies, Modern Golf takes a unique, employee centralized approach to improve the performance and efficiency of golfing clubs. We believe that first impressions with a member or client is the most important aspect to a successful customer-oriented business. By teaching efficient workplace practices and training in white-glove styled customer service, employees find themselves more dedicated to their job, resulting in increased satisfaction from management and clients"
    },
    {
      question: "If our organization has a high employee turnover rate is it worth investing money on individual training?",
      answer:
        "Yes, of course! That is an issue that most clubs face. In our experience, we have noticed that with a well-developed onboarding process and specialized training, employees will not only make more in tips, but find their job more enjoyable. This in turn will actually decrease a club’s employee turnover rate and increase overall production."
    },
    {
      question: "My club is a non-tipping organization, would I still benefit from Modern Golf’s services?",
      answer:
        "Yes, there are many ways to motivate employees in the industry by using other types of creative solutions, incentives are a powerful tool and do not always need to be monetary."
    },
    {
      question: "Does Modern Golf have solutions for clubs that already have well-trained teams?",
      answer:
        "At Modern Golf we believe there is always room for improvement. With the help of our digital products and on-site solutions, clubs can expect to streamline their operations and offer beneficial solutions to their customers and members."
    },
    {
      question: "Do you keep in contact with customers after your consulting services are performed?",
      answer:
        "Yes, we reach out on a monthly basis for the first year and answer any questions you may have and continue to help with implementation of our services in a smaller capacity. At Modern Golf, we value the relationships we establish with our clientele and will strive to improve upon them as time progresses."
    },
    {
      question: "Do you have packages or add on services that aim to revamp or train our courses turf management team?",
        answer:
          "As of this moment, the answer is no. That being said, we retain the required skills and materials to provide services tailored for turf care. We plan to offer those services in the near future, and  will provide our existing and prior customers priority when the packages are released.",

    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      {/* <DecoratorBlob1/>
      <DecoratorBlob2 /> */}
    </Container>
  );
};
