import React, {useState} from 'react'
import Header from '../components/Header'
import Footer2 from '../components/MiniCenteredFooter.js'
import AnimationRevealPage from './AnimationRevealPage.js'
import ContactUs from '../components/TwoColContactUsWithIllustrationFullForm.js'

function Contact() {
    return (
        <>
            <AnimationRevealPage>
            <Header /> 
            <ContactUs />
            <Footer2 />
            </AnimationRevealPage>
            
         </>
 
    
    );
}

export default Contact