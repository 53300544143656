import React, {useState} from 'react'
import Footer from '../components/Footer'
import ProductPlans from '../components/ProductPlans.js'
import AnimationRevealPage from './AnimationRevealPage.js'
import Header from '../components/Header'
import FAQ from '../components/SingleColFAQ'
import Testimonial from '../components/ThreeColumnWithProfileImage.js'
import Footer2 from '../components/MiniCenteredFooter.js'

function Service() {
    return (
        <>
            <AnimationRevealPage>
            <Header />
            <ProductPlans />
            <Testimonial
                heading="Our Paying Customers"
            />
            <FAQ />
            <Footer2 />
            </AnimationRevealPage>
         </>
 
    
    );
}

export default Service