import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import video from "../assets/video2.mp4"
import { PrimaryButton as PrimaryButtonBase } from "./Buttons.js";
import HeroPic from "../assets/HeroPic.jpg"

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "./light.js";
import ResponsiveVideoEmbed from "./ResponsiveVideoEmbed.js";


const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0 font-body text-base`
]);

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 font-body font-bold text-base hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative h-screen bg-center bg-cover`}
  background-image: url(${HeroPic});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2 font-body font-bold `}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-[#000080] font-body font-bold px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-body font-medium text-lg`;

const PrimaryAction = tw.button `px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-[#000080] font-body font-bold rounded shadow transition duration-300 hocus:bg-[#000080] hocus:text-gray-100 focus:shadow-outline` ;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;


export default () => {
  
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#">
        Home
      </NavLink>
      <NavLink href="/service">
        Products
      </NavLink>
      <NavLink href="/about">
        About
      </NavLink>
      <NavLink href="/contact">
        Contact
      </NavLink>
    </NavLinks>,
  ];

  

  return (
    <Container>
      {/* <OpacityOverlay /> */}
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>We have now launched our operations.</Notification>
            <Heading>
              <span>Hire The Best</span>
              <br />
              <SlantedBackground className="font-black">Consultants.</SlantedBackground>
            </Heading>
            <PrimaryButton as="a" href='/about'>
              Learn More
            </PrimaryButton>
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
