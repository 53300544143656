import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
// import { ReactComponent as SvgDotPatternIcon } from '../assets/dot-pattern.svg'
import { SectionHeading as HeadingTitle } from "./Headings.js";
import img1 from '../assets/card_img1.png'
import img2 from '../assets/card_img2.png'
import img3 from '../assets/card_img3.png'

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-2/4 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-4xl font-body font-bold text-[#4d0011]`;
const Description = tw.p`mt-2 text-xl font-body leading-loose text-gray-900`;

export default () => {
  const cards = [
    {
      imageSrc:img1,
    //   subtitle: "Paid",
      title: "Smooth Operation",
      description:
        "We examine what other clubs are doing to stay relevant and competitive in this fast-paced world, and which ones are doing it best. We then strategize using tools, resources, and our experience in order to understand the implications of every choice our clients can make. This allows us to give your club the tools to continue to succeed in the future."
    },

    {
      imageSrc:img2,
    //   subtitle: "Free",
      title: "Staff Pride",
      description:"We use these tools to help provide detailed instructions for new hire vetting and training in an effort to incentivize hard work and promote optimal working conditions. Get in touch to learn more about how our services can help you and your staff."
    },

    {
      imageSrc:img3,
    //   subtitle: "Exclusive",
      title: "Longevity",
      description:
        "With years of experience, our staff has the capabilities and expertise to take your business to the next level. At Modern Golf Consulting, we combine our insights and skills to transform your processes and strategies, and in turn, your club. We’re proud to help shape and improve how our clients structure and manage their business. As a Deluxe Client of MGC we will inform you on future models that will continue to enhance performance year after year."
    }
  ];

  return (
    <Container className="main-hero">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Our Strenghts</HeadingTitle>
          {/* <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
