import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "./Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "./Headings.js";
import gregimg from '../assets/Greg.jpg'
import joshimg from '../assets/Josh.jpg'

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium text-lg leading-loose font-body`;
const CustomerName = tw.p`mt-5 text-[#4d0011] font-semibold uppercase text-sm tracking-wide`;

export default ({
  subheading = "Testimonials",
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc: gregimg,
      quote:
        "After our club worked with MGC for just a month I personally saw improvement in overall performance and efficiency from the staff. MGC offers a unique approach to keeping staff and members happy. I would highly recommend their services for other courses.",
      customerName: "Greg"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      quote:
        "I had a very pleasant experience working with the staff at MGC. My trainers, Mitch and Jay were both extremely friendly and went above and beyond in making it clear their best intentions for the club. This type of service is long overdue in the golf industry",
      customerName: "Joshua"
    },
    {
      imageSrc: joshimg,
      quote:
        "Two thumbs up! We really enjoy the manuals that MGC has to offer. Surprisingly, they work super well. We had no expectations going in, but we are always looking to make improvements. Overall, way better than we expected. An easy and affordable 5 star experience hands down!",
      customerName: "Derek"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                {/* <Image src={testimonial.imageSrc} /> */}
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>
    </Container>
  );
};
