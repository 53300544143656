import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoOnly from '../assets/logoOnly.png'



function Navbar(){
    const [click, setClick] = useState(false);
    const [button1, setButton] = useState(true);
    const [navbar, setNavBar] = useState(false);
    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }else{
            setButton(true);
        }
    }
    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);


    const changeBackground = () => {
        if(window.scrollY >= 0){
            setNavBar(true);
        }else{
            setNavBar(false);
        }
    }
    window.addEventListener('scroll', changeBackground);


    return(
        <>
            <nav className = 'navbar active'>
                <div className = "navbar-container">
                    <div className = 'logo-container'>
                        <Link to = "/" className = "navbar-logo" onClick={closeMobileMenu}>
                            <img className = 'cmp-logo'
                                src={logoOnly}
                                alt="logo"/>
                        </Link>
                    </div>
                    <div className='menu-icon' onClick={() => {setNavBar(); handleClick(); }}>
                        <i className = {click ? 'fas fa-times' : 'fas fa-bars'}/>
                    </div>
                    <ul className={click ? 'nav-menu active': 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}> Home </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}> About </Link>
                        </li>   
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}> Contact </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/service' className='nav-links' onClick={closeMobileMenu}> Services </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
