import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../components/Headings.js";
import { SectionDescription } from "../components/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../components/Buttons.js";
import { Container, ContentWithPaddingXl } from "../components/Layouts.js";

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer1 = tw.div`flex justify-center flex-col lg:flex-row items-center lg:items-stretch relative`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-3xl font-body`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-2xl my-1 font-body`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest font-body`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1 `}
  .feature {
    ${tw`mt-5 first:mt-0 text-base font-medium font-body`}
    &:not(.mainFeature) {
      ${tw`text-gray-600 text-base`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;



export default ({
  subheading = "Pricing",
  heading = "Customizable Plans.",
  description = "",
  plans = null,
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "/contact"
}) => {
  const defaultPlans = [
    {
      name: "Cart Care Manual",
      price: "$499",
      duration: "One Time Payment",
      mainFeature: "Product Features",
      features: ["Proper cleaning and detailing procedures", "Upkeep and care of essential equipment", "Access to Opening/Mid/Closing shift checklists", "Cart presentation and inventory", "Review and quiz material for employees"],
    },
    
    {
      name: "Client Relations Manual",
      price: "$749",
      duration: "One Time Payment",
      mainFeature: "Product Features",
      features: ["Going Above and Beyond approach", "Conquering conflict in the workplace", "Appearance and preparation with white glove service", "Incorporating a full service mentality", "Review and quiz material for employees"],
    },
    
    {
      name: "Range Manual",
      price: "$499",
      duration: "One Time Payment",
      mainFeature: "Product Features",
      features: ["How to get the most out of your picker and ball washer", "Stocking the Range/Up Keep/Presentation", "Our “Down Time” philosophy", "Access to Opening/Mid/Closing checklists", "Review and quiz material for employees"],
    },
  ];

  const plans1 = [
    {
      name: "Bundle All Three Features",
      price: "$1999",
      duration: "One Time Payment",
      mainFeature: "Product Features",
      features: ["Includes Cart Care Manual, Client Relations Manual, and Range Manual","Consultation for customized packages", "Hard-copy accountability and checklist cards for employees", "Employer Interviewing / Recruiting Templates", "Brief onboarding packets for new hire employees", "MGC Deluxe Client Access"],
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      // background: rgb(56, 178, 172);
      // background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
      background: #000080;
background: -webkit-linear-gradient(left, #000080, #3F54C3);
background: -moz-linear-gradient(left, #000080, #3F54C3);
background: linear-gradient(to right, #000080, #3F54C3);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

  const highlightGradientsCss1 = [
    css`
    background: #FFD700;
    background: -webkit-linear-gradient(left, #FFD700, #F0E68C);
    background: -moz-linear-gradient(left, #FFD700, #F0E68C);
    background: linear-gradient(to right, #FFD700, #F0E68C);
    `,
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton as="a" href={primaryButtonUrl} css={!plan.featured && highlightGradientsCss[index]}> <span tw="font-body text-lg">{primaryButtonText}</span>  </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>

        <PlansContainer1>
          {plans1.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss1[index % highlightGradientsCss1.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton css={!plan.featured && highlightGradientsCss1[index]}> <span tw="font-body text-lg">{primaryButtonText}</span> </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer1>

      </ContentWithPaddingXl>
    </Container>
  );
};