import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../components/Heading.js"
import { ReactComponent as QuotesLeftIcon } from "../assets/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../assets/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/arrow-right-2-icon.svg";
import Mitch from "../assets/Mitch.JPG"
import Jay from "../assets/Jay.JPG"

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-16`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center sm:flex-col mt-20 sm:justify-center lg:flex-row xl:flex-row md:flex-col md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-12 w-11/12 pr-6 md:w-1/2 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded md:w-10/12 lg:w-10/12 h-10/12 xl:w-10/12 flex-shrink-0 h-80 md:h-10/12 lg:h-10/12 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-body text-gray-600 font-normal text-lg lg:text-base xl:text-xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl font-body xl:text-3xl text-[#4d0011]`;
const CustomerTitle = tw.p`font-medium text-gray-600 font-body text-lg`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-[#4d0011] absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-[#4d0011] absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);


export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [

    {
      imageSrc:Mitch,
      quote:
        "Born and raised in Palm Desert, California, Mitchell has been immersed in the affluent golfing community from a young age. By 11 years of age, Mitchell was a dedicated daily golfer and by 12 years old was already working in the service industry. From ages 14-19, he worked in the boat marina industry, whose service revolved largely around meticulous attention to detail, it was there that Mitchell adapted a vast skillset of enhancing a customer's experience. With an Economics and Business education from the University of Texas at Austin, Mitchell seeks to build a company that shares the knowledge and expertise he has acquired from years of customer service in the golfing, boating, musical production, and food service industries.  He is incredibly dedicated to the things that matter most to him and will see to it that clients benefit from his services.",
      customerName: "Mitch Conrow",
      customerTitle: "Co-Founder/Treasurer"
    },
    {
      imageSrc:Jay,
      quote:
        "At a young age Jay was introduced into the golf industry by his uncle.  By the age of 13 he had become a AA Caddie working at Tedesco Country Club in Massachusetts. It was there where he excelled and honed his customer service skills to near perfection.  After years of working for country clubs he decided to gain practical sales experience. By the the age of 19 he was a top representative for his company.  Rather than pursuing a formal education he decided to gain hands on practical experience in low level positions in the golf industry.  With the knowledge and experience that Jay has gained, he wants nothing more than to give back to the community and pass on the skills that he has learned. He is very passionate about teaching and will make the most out of any opportunity to do so.",
      customerName: "Jay Cub Lever ",
      customerTitle: "Founder/President"
    }
  ];


  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Meet Our Team</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          {/* <TestimonialSlider nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}> */}
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                </ImageContainer>
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>{testimonial.quote}</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          {/* </TestimonialSlider> */}
        </TestimonialSliderContainer>
      </Content>
    </Container>
  );
};
