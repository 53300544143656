import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import './input.css'
import Landing from './pages/Landing.js'
import About from './pages/About.js'
import Service from './pages/Service.js'
import Contact from './pages/Contact.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path ="/service" element = {<Service />} />
        <Route path = "/contact" element = {<Contact />} /> 
      </Routes>
    </Router>
  );
}

export default App;
