import React, { useState } from 'react';
import './HomeContent.css';


function HomeContent() {

    const [divColor, setBagColor] = useState(false);
    const changeBackground = () => {
        if(window.scrollY >= 400){
            setBagColor(true);
        }else{
            setBagColor(false);
        }
    }
    window.addEventListener('scroll', changeBackground);  

    return (
        <div className = {divColor ? 'primary-container-active' : 'primary-container'}>
            <div className = {divColor ? 'primary-heading-content' : 'primary-heading-content-active'}>
                <h1 className = 'pg3-heading'> Why We're Better:
                </h1>
            </div>
            <div className = {divColor ? 'primary-para-content' : 'primary-para-content-active'}>
                <p className = 'pg3-para'>
                Modern Golf Consulting exists to solve the critical issues facing our clients in the golf industry, both large and small. 
                Our unique approach is not only what differentiates us, but also what makes us successful. We provide a broad range of
                 services and training packages to help clubs facilitate change, achieve their vision, and optimize performance and 
                 productivity.
                </p>
            </div>

            
            
        </div>

    );
}

export default HomeContent;